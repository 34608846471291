<template>
    <div class="tripctxmenu-container" >
        <vs-button size="large" block transparent @click="callback('delete')">
            <img src="../../assets/icons/ctx/delete.png" style="width: 17px; height: 17px;" />{{ $t('common.delete') }}
        </vs-button>
    </div>
    </template>
    
    <script>
    export default {
        name: 'tripCtxMenu',
        props: {
            callback: Function
        }
    }
    </script>
    
    <style scoped>
    .tripctxmenu-container {
        display: flex;
        flex-direction: column;
        background: #ECEBED;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
    }
    </style><style>
    .tripctxmenu-container .vs-button__content {
        justify-content: flex-start;
        align-items: center;
        font-weight: 600!important;
    }
    


    .tripctxmenu-container .vs-button:nth-child(2) {
        color: var(--primary) !important;
    }
    </style>
    <style>
    .tripctxmenu-container .vs-button {
        margin: 0;
        border-radius: 0!important;
        box-shadow: inset 0px -1px 0px rgba(208, 201, 214, 0.4);
    }
    
    </style>