<template>
<div>
    <div class="newtripdialog-container">
        <h2>{{ $t('trips.newTrip') }}</h2>

        <vs-input v-model="name"  placeholder="Come si chiamerà il viaggio?" style="margin-top: 20px;" block>
            <template #icon>
                <img src="../../assets/icons/input/edit.png" style="width: 32px;"/>
            </template>
        </vs-input>
        <div style="display: flex; width: 100%; justify-content: space-between; gap: 10px;">
            <vs-button size="large" border block @click="callback(null, true)">
                {{ $t('common.cancel') }}
            </vs-button>
            <vs-button size="large" block @click="callback(name, true)">
                {{ $t('common.continue') }}
            </vs-button>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: "newTripDialog",
    props: {
        callback: Function
    },
    data() {
        return {
            name: ''
        }
    },
    components: {}
}
</script>

<style scoped>
p,
h3,h2 {
    margin: 0;
}

.newtripdialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 40px;
    gap: 10px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}
</style>
